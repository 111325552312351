"use client";
import { MotionStyle, Variants, motion } from "framer-motion";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function TOMImage(props: {
  src: string;
  alt: string;
  style?: MotionStyle;
  initial?: string | boolean;
  whileInView?: string;
  variants?: Variants;
  className?: string;
  hidden?: boolean;
}) {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {!loaded && !props.hidden && <Skeleton containerClassName={props.className} className="w-full h-full" />}
      <motion.img
        className={props.className}
        initial={props.initial}
        whileInView={props.whileInView}
        variants={props.variants}
        style={props.style}
        hidden={props.hidden}
        loading="lazy"
        onLoad={() => setLoaded(true)}
        src={props.src}
        alt={props.alt}
      />
    </>
  );
}

export default TOMImage;
