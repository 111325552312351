import TOMImage from "@/src/components/ui/tomImage";
import { useEffect, useRef } from "react";

function NewsPage() {
  const pointer = useRef(0);
  useEffect(() => {
    const interval = setInterval(() => {
      const childrens = document.getElementById("image-container")?.children;
      if (childrens) {
        childrens[pointer.current]?.scrollIntoView({
          behavior: "smooth",
        });
        pointer.current++;
        if (pointer.current >= childrens.length) {
          pointer.current = 0;
        }
      }
    }, 5000);
    return () => clearInterval(interval);
  });

  return (
    <div className="relative h-dvh w-full [&>div]:w-full">
      <div
        id="image-container"
        className="h-full inline-flex overflow-x-scroll overflow-y-hidden [&>img]:self-stretch hide-scrollbar"
      >
        <TOMImage
          src="images/news/0.jpg"
          alt="news"
          className="h-full w-full min-w-full object-cover"
        />
        <TOMImage
          src="images/news/1.jpg"
          alt="news"
          className="h-full w-full min-w-full object-cover"
        />
      </div>
      <div className="absolute bottom-32 text-white px-10">
        Our lead architect delivered an inspiring and forward-looking
        presentation at the World Urban Planning Education Network, unveiling
        our visionary outlook on the future transportation system and the
        revolutionary PRT technology. We are missioned to make a tangible impact
        and shape a future that embodies our aspirations.
      </div>
      <div className="absolute flex justify-between bottom-10 text-white px-10">
        <span>2023.10.27</span>
        <span>Team: Joe Wong, YX Wang, Wilson Ng, XY Liao</span>
      </div>
    </div>
  );
}

export default NewsPage;
