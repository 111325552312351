import { useParams } from "react-router-dom";

export default function InvitationPage() {
  const { name } = useParams();
  const mapping: { [name: string]: { inviter: string; invitee: string } } = {
    "anson": {
      inviter: "Joe Wong",
      invitee: "Anson Ng",
    },
    "andy": {
        inviter: "Joe Wong",
        invitee: "Andy Wong",
    },
    "hazel": {
        inviter: "Toby Wong",
        invitee: "Hazel",
    },
    "eric": {
        inviter: "Joe Wong",
        invitee: "Eric Chan",
    },
    "Irene": {
        inviter: "Joe Wong",
        invitee: "Irene Lau"
    },
    "Wilson": {
      inviter: "Joe Wong",
      invitee: "Wilson Ng",
    },
    "Maverick": {
      inviter: "Joe Wong",
      invitee: "Maverick",
    },
  };
  if (!name || !Object.keys(mapping).includes(name)) {
    return null;
  }

  function handleClick(e: React.MouseEvent<HTMLDivElement>) {
    e.currentTarget.classList.add("scale-[3]");
    document.getElementById("logo")?.classList.add("!pointer-events-auto");
    document.getElementById("logo")?.classList.add("!top-24");
    document.getElementById("logo")?.classList.add("!w-16");
    document.getElementById("logo")?.classList.add("!h-16");
    document.getElementById("text")?.classList.add("!scale-100");
  }

  return (
    <div className="flex items-center justify-center h-screen w-screen bg-black overflow-hidden">
      <img
        id="logo"
        src="/logo-white.png"
        alt="logo"
        className="absolute w-[24vw] h-[24vh] lg:w-[12vw] lg:h-[12vh] object-contain animate-rotation z-10 top-[calc(50vh-12vh)] lg:top-[calc(50vh-6vh)] pointer-events-none transition-all"
        onClick={() => window.location.href = "/"}
      />
      <div
        className="gradient-background transition-transform z-0"
        onClick={handleClick}
      />
      <div
        id="text"
        className="absolute top-48 h-[calc(100dvh-12rem)] scale-0 delay-800 transition-transform text-lg text-white p-8 lg:px-[20vw] overflow-y-scroll hide-scrollbar"
      >
        <h1 className="w-fit mx-auto text-2xl text-center font-medium">
          Grand Opening <br/>TONS OF MINDS
        </h1>
        <p className="mt-16">Dear {mapping[name].invitee},</p>
        <p className="text-justify mt-8">
          I'm thrilled to invite you to the grand opening of our 1st ever studio!
        </p>
        <p className="text-justify mt-4">
          As one of my closest friends, your presence would mean the world to me
          on this special day.
        </p>
        <p className="text-justify mt-8">Date: 14 Sep 2024 (Sat)</p>
        <p className="text-justify">Time: 18:00pm</p>
        <p className="text-justify">Venue: Unit 7, 1/F 7 Sheung Hei St., San Po Kong</p>
        <p className="text-justify">Content: Shabu-Shabu</p>
        <p className="text-justify mt-8">Bring your fav drinks to share!</p>
        <p className="text-justify mt-8">{mapping[name].inviter}</p>
        <img src="/map.png" alt="map" className="mt-12"/>
        <p className="text-center mt-12 text-sm">© 2024 Tons of Minds Limited, all rights reserved.</p>
      </div>
    </div>
  );
}
