import { useEffect, useState } from "react";

function useWindowSize(defaultWidth: number, defaultHeight: number) {
  const [size, setSize] = useState({
    height: defaultHeight,
    width: defaultWidth,
  });
  useEffect(() => {
    const handleResizeWindow = (): void => {
      setSize({ height: window.innerHeight, width: window.innerWidth });
    }
      
    window.addEventListener("resize", handleResizeWindow);
    handleResizeWindow();
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  return size;
}

export default useWindowSize;
