import { motion } from "framer-motion";

function NotFound() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.5,
      }}
      className="full-screen flex items-center justify-center"
    >
      <h1 className="font-semibold text-2xl md:text-4xl lg:text-6xl text-destructive">Constructing...</h1>
    </motion.div>
  );
}

export default NotFound;
