import { ReactNode, useRef } from "react";

function MovingBackgroundDiv(props: {
  scroll: number;
  factor: number;
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      style={{
        ...props.style,
        backgroundPosition: ref.current
          ? `50% ${Math.round(
              (ref.current.offsetTop - props.scroll) * props.factor
            )}px`
          : "50% 0",
      }}
    >
      {props.children}
    </div>
  );
}

export default MovingBackgroundDiv;
