import { useEffect, useRef, useState } from "react";
import { cn } from "../utils/utils";
import { motion } from "framer-motion";
import MapSvg from "../components/svg/map";
import Footer from "../components/footer";
import TOMImage from "../components/ui/tomImage";

const areas = [
  {
    title: "Architectural Design",
    desc: "We are envisioning a innovative and sustainable built environment. With a focus on modular structures and zero waste architecture, our team of experts creates captivating spaces that harmonize functionality and aesthetics.",
  },
  {
    title: "AECO Technology",
    desc: "We integrate manpower and machine, harness information, and boost productivity. Our solutions optimize workflows, enabling seamless collaboration and data utilization. By unleashing creative minds, we drive innovation in the industry, delivering efficient results that exceed expectations.",
  },
  {
    title: "Spatial Curation",
    desc: "Our space curators offer premium interior design tailored to suit every client's prestigious needs and refined tastes. Our team of talented curators ensures that every space is meticulously designed to reflect individual preferences, combining elegance, sophistication, and a touch of luxury.",
  },
  {
    title: "Brand Identity and Storytelling",
    desc: "With a keen eye for detail and a deep understanding of your brand's essence, values, and purpose, our expert team designs compelling visual and verbal elements. Through our creative prowess, we engage audiences, foster brand loyalty, and leave an indelible impression that sets your brand apart.",
  },
]

function HomePage(props: { yScroll?: number; yScrollProgress?: number }) {
  const texts = [
    "Curious",
    "Innovative",
    "Collaborative",
    "Dynamic",
    "Problem-Solving",
  ];
  const pointer = useRef([0, 1, 1]);
  const [text, setText] = useState("");
  const [blink, setBlink] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      pointer.current[1] += pointer.current[2];
      if (pointer.current[1] > texts[pointer.current[0]].length + 6) {
        pointer.current[2] = -1;
      }
      if (pointer.current[1] < 0) {
        pointer.current[2] = 1;
        pointer.current[0] += 1;
      }
      if (pointer.current[0] >= texts.length) {
        pointer.current[0] = 0;
      }
      if (pointer.current[1] === texts[pointer.current[0]].length) {
        setBlink(!blink);
      }
      setText(texts[pointer.current[0]].substring(0, pointer.current[1] + 1));
    }, 100);
    return () => clearInterval(interval);
  });

  return (
    <div>
      <div className="h-dvh relative flex justify-center items-center">
        <video
          className="absolute w-full h-dvh object-cover bg-black"
          autoPlay
          muted
          loop
        >
          <source src="1.mp4" type="video/mp4" />
        </video>
        <h1 className="text-center text-white p-3 md:p-5 text-4xl md:text-6xl lg:text-8xl font-semibold bg-clip-text bg-gradient-to-r from-accent-alt to-accent-foreground z-10">
          <span>{text}</span>
          <span className={cn("font-normal", blink ? "animate-blink" : "")}>
            |
          </span>
          <span className="ml-2 md:ml-4 lg:ml-6">MINDS</span>
        </h1>
      </div>
      <motion.div
        className="h-dvh relative flex flex-col justify-center items-center rounded-3xl gap-2 md:gap-4 bg-secondary"
        style={{
          translateY: (props.yScroll || 0) * -0.4,
          scaleX: Math.min((props.yScrollProgress || 0) / 2 + 0.9, 1),
          scaleY: 1,
        }}
      >
        <h2 className="w-full text-center centered text-white text-2xl md:text-4xl lg:text-6xl text-shadow z-10">
          MINDS that Fulfill Your Vision
        </h2>
        <TOMImage
          src="/images/cover.jpg"
          alt="Project"
          className="h-[90%] w-[95%] object-cover rounded-xl"
          initial="hidden"
          whileInView="show"
          variants={{
            hidden: { opacity: 0.4 },
            show: { opacity: 1, transition: { delay: 1, duration: 1 } },
          }}
        />
      </motion.div>
      <motion.div
        className="text-center"
        style={{
          translateY: (props.yScroll || 0) * -0.2 + 50,
        }}
      >
        <h2 className="text-primary text-2xl md:text-4xl lg:text-6xl mb-6">
          We are transforming the built environment
        </h2>
        <div className="flex flex-wrap justify-around px-4 [&>div]:bg-slate-200/50 [&>div]:w-full [&>div]:md:w-[40%] [&>div]:p-4 lg:[&>div]:p-8 [&>div]:rounded-xl [&>div]:mt-4 lg:[&>div]:mt-8">
          {areas.map((area, index) => {
            return (
              <motion.div
                initial="initial"
                variants={{
                  initial: {
                    y: 50,
                    opacity: 0,
                  },
                  animate: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.5,
                      duration: 1,
                      ease: "easeIn",
                    },
                  },
                }}
                whileInView={"animate"}
                key={`service-${index}`}
              >
                <div className="text-xl">{area.title}</div>
                <div className="text-sm">{area.desc}</div>
              </motion.div>
            );
          })}
        </div>
      </motion.div>
      <div className="relative h-dvh w-full md:px-4 lg:px-6 mb-8">
        <MapSvg className="max-h-dvh" />
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
