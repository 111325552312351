import MovingBackgroundDiv from "@/src/components/ui/movingBackgroundDiv";
import { useRef } from "react";

const areas = [
  {
    title: "Architectural Design",
    image: "images/about/1001.jpg",
  },
  {
    title: "Spatial Curation",
    image: "images/about/2001.jpg",
  },
  {
    title: "AECO Technology",
    image: "images/about/3001.jpg",
  },
  {
    title: "Brand Identity and Storytelling",
    image: "images/about/4001.jpg",
  },
]

function AboutPage(props: {
  yScroll?: number;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={containerRef}
      className="font-semibold [&>div]:bg-fixed [&>div]:bg-cover [&>div]:bg-no-repeat [&>div]:full-screen [&>div]:text-2xl [&>div]:md:text-4xl [&>div]:lg:text-6xl [&>div]:flex [&>div]:items-center [&>div]:justify-center"
    >
      {areas.map(
        (area, index) => {
          return (
            <MovingBackgroundDiv
              scroll={props.yScroll || 0}
              factor={0.5}
              style={{
                backgroundImage: `url(${area.image})`,
              }}
              key={`about-${index}`}
            >
              <span className="text-shadow text-white text-center">{area.title}</span>
            </MovingBackgroundDiv>
          );
        }
      )}
    </div>
  );
}

export default AboutPage;
