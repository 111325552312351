import { createBrowserRouter, useLocation } from "react-router-dom";
import HomePage from "@/src/pages/page";
import NotFound from "@/src/components/not-found";
import ContactPage from "@/src/pages/contact/page";
import Nav from "@/src/components/nav";
import { ReactElement, cloneElement, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Footer from "@/src/components/footer";
import AboutPage from "@/src/pages/about/page";
import NewsPage from "./pages/news/page";
import InvitationPage from "./pages/invitation/page";

const PageContainer = (props: {
  children: ReactElement;
  pageTransition: boolean;
  includeNav: boolean;
  includeFooter: boolean;
}) => {
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [yScroll, setYScroll] = useState(0);
  const [yScrollProgress, setYScrollProgress] = useState(0);

  useEffect(() => {
    setYScroll(0);
    setYScrollProgress(0);
  }, [location.pathname]);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        ref={containerRef}
        key={location.pathname}
        className="h-dvh hide-scrollbar hide-main-scrollbar overflow-y-scroll overflow-x-hidden bg-background"
        // variants={
        //   props.pageTransition
        //     ? {
        //         initial: { y: -1000, scale: 0 },
        //         animate: { y: 0, scale: 1, transition: { duration: 0.3 } },
        //         exit: { y: -1000, scale: 0, transition: { duration: 0.3 } },
        //       }
        //     : undefined
        // }
        onScroll={() => {
          if (!containerRef.current) return;
          setYScroll(containerRef.current.scrollTop);
          setYScrollProgress(
            containerRef.current.scrollTop / containerRef.current.scrollHeight
          );
        }}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        {props.includeNav && <Nav />}
        {cloneElement(props.children, {
          yScroll: yScroll,
          yScrollProgress: yScrollProgress,
        })}
        {props.includeFooter && <Footer />}
      </motion.div>
    </AnimatePresence>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PageContainer
        includeNav={true}
        includeFooter={false}
        children={<HomePage />}
        pageTransition={false}
      />
    ),
    children: [],
  },
  {
    path: "about",
    element: (
      <PageContainer
        includeNav={true}
        includeFooter={true}
        children={<AboutPage />}
        pageTransition={true}
      />
    ),
  },
  {
    path: "news",
    element: (
      <PageContainer
        includeNav={true}
        includeFooter={true}
        children={<NewsPage />}
        pageTransition={true}
      />
    ),
  },
  {
    path: "contact",
    element: (
      <PageContainer
        children={<ContactPage />}
        includeNav={true}
        includeFooter={true}
        pageTransition={true}
      />
    ),
  },
  {
    path: "invitation/:name",
    element: <InvitationPage />,
  },
  {
    path: "*",
    element: (
      <PageContainer
        children={<NotFound />}
        includeNav={true}
        includeFooter={true}
        pageTransition={true}
      />
    ),
  },
]);

export default router;
